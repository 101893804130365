import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        paging: true,
        pageLength: -1,
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: 'SEARCH',
        },
        lengthMenu: [
          [25, 50, 100, -1],
          ['25', '50', '100', 'All'],
        ],
        dom: 'Blfrtip',
        buttons: [
          {
            extend: 'copyHtml5',
            text: '<i class="fas fa-copy"></i> Copy',
            titleAttr: 'Copy',
          },
          {
            extend: 'print',
            text: '<i class="fas fa-print"></i> Print',
            titleAttr: 'Print',
            title: 'camper-list-table' + Date.now(),
            exportOptions: {
              modifier: {
                selected: null,
              },
            },
          },
          {
            extend: 'csvHtml5',
            text: '<i class="fas fa-table"></i> CSV',
            titleAttr: 'CSV',
            title: 'camper-list-table' + Date.now(),
            exportOptions: {
              modifier: {
                selected: null,
              },
            },
          },
        ],
      });
    }
  }
}

